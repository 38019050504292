import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { ResolvedList, ResolvedListItem, ResolvedUser } from '../server/graphql/controllers';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  email: Scalars['String'];
  lists: Array<List>;
};

export type List = {
  __typename?: 'List';
  id: Scalars['ID'];
  owner: User;
  name: Scalars['String'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  items: Array<ListItem>;
  shared: Scalars['Boolean'];
};

export type ListItem = {
  __typename?: 'ListItem';
  id: Scalars['ID'];
  list: List;
  description: Scalars['String'];
  complete: Scalars['Boolean'];
  position: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  lastUpdatedUser?: Maybe<User>;
};

export type LoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  jwt: Scalars['String'];
};

export type CreateAccountInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CreateAccountResponse = {
  __typename?: 'CreateAccountResponse';
  jwt: Scalars['String'];
};

export type CreateListInput = {
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
};

export type UpdateListInput = {
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type ShareListsInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CreateListItemInput = {
  listId: Scalars['ID'];
  description: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
};

export type UpdateListItemInput = {
  description?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

export type CompleteListItemInput = {
  id: Scalars['ID'];
  complete?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  lists: Array<List>;
  list: List;
  shareListsUsers: Array<User>;
};


export type QueryListArgs = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginResponse;
  createAccount: CreateAccountResponse;
  createList: List;
  updateList: List;
  deleteList: Scalars['ID'];
  shareLists: User;
  unshareLists: Scalars['ID'];
  createListItem: ListItem;
  updateListItem: ListItem;
  completeListItem: ListItem;
  removeCompletedListItems: Scalars['Int'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateListArgs = {
  input: CreateListInput;
};


export type MutationUpdateListArgs = {
  input: UpdateListInput;
};


export type MutationDeleteListArgs = {
  id: Scalars['ID'];
};


export type MutationShareListsArgs = {
  input: ShareListsInput;
};


export type MutationUnshareListsArgs = {
  id: Scalars['ID'];
};


export type MutationCreateListItemArgs = {
  input: CreateListItemInput;
};


export type MutationUpdateListItemArgs = {
  input: UpdateListItemInput;
};


export type MutationCompleteListItemArgs = {
  input: CompleteListItemInput;
};


export type MutationRemoveCompletedListItemsArgs = {
  listId: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  listItemChanged: ListItem;
  completedListItemsRemoved: Scalars['Boolean'];
};


export type SubscriptionListItemChangedArgs = {
  listId: Scalars['ID'];
};


export type SubscriptionCompletedListItemsRemovedArgs = {
  listId: Scalars['ID'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Date: ResolverTypeWrapper<Scalars['Date']>;
  User: ResolverTypeWrapper<ResolvedUser>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  List: ResolverTypeWrapper<ResolvedList>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ListItem: ResolverTypeWrapper<ResolvedListItem>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  LoginInput: LoginInput;
  LoginResponse: ResolverTypeWrapper<LoginResponse>;
  CreateAccountInput: CreateAccountInput;
  CreateAccountResponse: ResolverTypeWrapper<CreateAccountResponse>;
  CreateListInput: CreateListInput;
  UpdateListInput: UpdateListInput;
  ShareListsInput: ShareListsInput;
  CreateListItemInput: CreateListItemInput;
  UpdateListItemInput: UpdateListItemInput;
  CompleteListItemInput: CompleteListItemInput;
  Query: ResolverTypeWrapper<{}>;
  Mutation: ResolverTypeWrapper<{}>;
  Subscription: ResolverTypeWrapper<{}>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Date: Scalars['Date'];
  User: ResolvedUser;
  ID: Scalars['ID'];
  String: Scalars['String'];
  List: ResolvedList;
  Boolean: Scalars['Boolean'];
  ListItem: ResolvedListItem;
  Int: Scalars['Int'];
  LoginInput: LoginInput;
  LoginResponse: LoginResponse;
  CreateAccountInput: CreateAccountInput;
  CreateAccountResponse: CreateAccountResponse;
  CreateListInput: CreateListInput;
  UpdateListInput: UpdateListInput;
  ShareListsInput: ShareListsInput;
  CreateListItemInput: CreateListItemInput;
  UpdateListItemInput: UpdateListItemInput;
  CompleteListItemInput: CompleteListItemInput;
  Query: {};
  Mutation: {};
  Subscription: {};
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lists?: Resolver<Array<ResolversTypes['List']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListResolvers<ContextType = any, ParentType extends ResolversParentTypes['List'] = ResolversParentTypes['List']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ListItem']>, ParentType, ContextType>;
  shared?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListItem'] = ResolversParentTypes['ListItem']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  list?: Resolver<ResolversTypes['List'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  complete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  lastUpdatedUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginResponse'] = ResolversParentTypes['LoginResponse']> = {
  jwt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAccountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAccountResponse'] = ResolversParentTypes['CreateAccountResponse']> = {
  jwt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  lists?: Resolver<Array<ResolversTypes['List']>, ParentType, ContextType>;
  list?: Resolver<ResolversTypes['List'], ParentType, ContextType, RequireFields<QueryListArgs, 'id'>>;
  shareListsUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  login?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'input'>>;
  createAccount?: Resolver<ResolversTypes['CreateAccountResponse'], ParentType, ContextType, RequireFields<MutationCreateAccountArgs, 'input'>>;
  createList?: Resolver<ResolversTypes['List'], ParentType, ContextType, RequireFields<MutationCreateListArgs, 'input'>>;
  updateList?: Resolver<ResolversTypes['List'], ParentType, ContextType, RequireFields<MutationUpdateListArgs, 'input'>>;
  deleteList?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationDeleteListArgs, 'id'>>;
  shareLists?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationShareListsArgs, 'input'>>;
  unshareLists?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationUnshareListsArgs, 'id'>>;
  createListItem?: Resolver<ResolversTypes['ListItem'], ParentType, ContextType, RequireFields<MutationCreateListItemArgs, 'input'>>;
  updateListItem?: Resolver<ResolversTypes['ListItem'], ParentType, ContextType, RequireFields<MutationUpdateListItemArgs, 'input'>>;
  completeListItem?: Resolver<ResolversTypes['ListItem'], ParentType, ContextType, RequireFields<MutationCompleteListItemArgs, 'input'>>;
  removeCompletedListItems?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationRemoveCompletedListItemsArgs, 'listId'>>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  listItemChanged?: SubscriptionResolver<ResolversTypes['ListItem'], "listItemChanged", ParentType, ContextType, RequireFields<SubscriptionListItemChangedArgs, 'listId'>>;
  completedListItemsRemoved?: SubscriptionResolver<ResolversTypes['Boolean'], "completedListItemsRemoved", ParentType, ContextType, RequireFields<SubscriptionCompletedListItemsRemovedArgs, 'listId'>>;
};

export type Resolvers<ContextType = any> = {
  Date?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  List?: ListResolvers<ContextType>;
  ListItem?: ListItemResolvers<ContextType>;
  LoginResponse?: LoginResponseResolvers<ContextType>;
  CreateAccountResponse?: CreateAccountResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { createAccount: (
    { __typename?: 'CreateAccountResponse' }
    & Pick<CreateAccountResponse, 'jwt'>
  ) }
);

export type ListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ListQuery = (
  { __typename?: 'Query' }
  & { list: (
    { __typename?: 'List' }
    & Pick<List, 'id' | 'name'>
    & { items: Array<(
      { __typename?: 'ListItem' }
      & Pick<ListItem, 'id' | 'description' | 'position' | 'complete'>
    )> }
  ) }
);

export type CompleteListItemMutationVariables = Exact<{
  input: CompleteListItemInput;
}>;


export type CompleteListItemMutation = (
  { __typename?: 'Mutation' }
  & { completeListItem: (
    { __typename?: 'ListItem' }
    & Pick<ListItem, 'id' | 'description' | 'position' | 'complete'>
  ) }
);

export type CreateListItemMutationVariables = Exact<{
  input: CreateListItemInput;
}>;


export type CreateListItemMutation = (
  { __typename?: 'Mutation' }
  & { createListItem: (
    { __typename?: 'ListItem' }
    & Pick<ListItem, 'id' | 'description' | 'position' | 'complete'>
  ) }
);

export type OnListItemChangedSubscriptionVariables = Exact<{
  listId: Scalars['ID'];
}>;


export type OnListItemChangedSubscription = (
  { __typename?: 'Subscription' }
  & { listItemChanged: (
    { __typename?: 'ListItem' }
    & Pick<ListItem, 'id' | 'description' | 'position' | 'complete'>
  ) }
);

export type RemoveCompletedListItemsMutationVariables = Exact<{
  listId: Scalars['ID'];
}>;


export type RemoveCompletedListItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCompletedListItems'>
);

export type CreateListMutationVariables = Exact<{
  input: CreateListInput;
}>;


export type CreateListMutation = (
  { __typename?: 'Mutation' }
  & { createList: (
    { __typename?: 'List' }
    & Pick<List, 'id' | 'name'>
  ) }
);

export type ListsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListsQuery = (
  { __typename?: 'Query' }
  & { lists: Array<(
    { __typename?: 'List' }
    & Pick<List, 'id' | 'name' | 'shared'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'username'>
    ) }
  )> }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'jwt'>
  ) }
);

export type ShareListsMutationVariables = Exact<{
  input: ShareListsInput;
}>;


export type ShareListsMutation = (
  { __typename?: 'Mutation' }
  & { shareLists: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  ) }
);

export type ShareListsUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ShareListsUsersQuery = (
  { __typename?: 'Query' }
  & { shareListsUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type UnshareListsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnshareListsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unshareLists'>
);


export const CreateAccountDocument = gql`
    mutation createAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    jwt
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const ListDocument = gql`
    query list($id: ID!) {
  list(id: $id) {
    id
    name
    items {
      id
      description
      position
      complete
    }
  }
}
    `;

/**
 * __useListQuery__
 *
 * To run a query within a React component, call `useListQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListQuery(baseOptions: Apollo.QueryHookOptions<ListQuery, ListQueryVariables>) {
        return Apollo.useQuery<ListQuery, ListQueryVariables>(ListDocument, baseOptions);
      }
export function useListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListQuery, ListQueryVariables>) {
          return Apollo.useLazyQuery<ListQuery, ListQueryVariables>(ListDocument, baseOptions);
        }
export type ListQueryHookResult = ReturnType<typeof useListQuery>;
export type ListLazyQueryHookResult = ReturnType<typeof useListLazyQuery>;
export type ListQueryResult = Apollo.QueryResult<ListQuery, ListQueryVariables>;
export const CompleteListItemDocument = gql`
    mutation completeListItem($input: CompleteListItemInput!) {
  completeListItem(input: $input) {
    id
    description
    position
    complete
  }
}
    `;
export type CompleteListItemMutationFn = Apollo.MutationFunction<CompleteListItemMutation, CompleteListItemMutationVariables>;

/**
 * __useCompleteListItemMutation__
 *
 * To run a mutation, you first call `useCompleteListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeListItemMutation, { data, loading, error }] = useCompleteListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteListItemMutation(baseOptions?: Apollo.MutationHookOptions<CompleteListItemMutation, CompleteListItemMutationVariables>) {
        return Apollo.useMutation<CompleteListItemMutation, CompleteListItemMutationVariables>(CompleteListItemDocument, baseOptions);
      }
export type CompleteListItemMutationHookResult = ReturnType<typeof useCompleteListItemMutation>;
export type CompleteListItemMutationResult = Apollo.MutationResult<CompleteListItemMutation>;
export type CompleteListItemMutationOptions = Apollo.BaseMutationOptions<CompleteListItemMutation, CompleteListItemMutationVariables>;
export const CreateListItemDocument = gql`
    mutation createListItem($input: CreateListItemInput!) {
  createListItem(input: $input) {
    id
    description
    position
    complete
  }
}
    `;
export type CreateListItemMutationFn = Apollo.MutationFunction<CreateListItemMutation, CreateListItemMutationVariables>;

/**
 * __useCreateListItemMutation__
 *
 * To run a mutation, you first call `useCreateListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListItemMutation, { data, loading, error }] = useCreateListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateListItemMutation, CreateListItemMutationVariables>) {
        return Apollo.useMutation<CreateListItemMutation, CreateListItemMutationVariables>(CreateListItemDocument, baseOptions);
      }
export type CreateListItemMutationHookResult = ReturnType<typeof useCreateListItemMutation>;
export type CreateListItemMutationResult = Apollo.MutationResult<CreateListItemMutation>;
export type CreateListItemMutationOptions = Apollo.BaseMutationOptions<CreateListItemMutation, CreateListItemMutationVariables>;
export const OnListItemChangedDocument = gql`
    subscription onListItemChanged($listId: ID!) {
  listItemChanged(listId: $listId) {
    id
    description
    position
    complete
  }
}
    `;

/**
 * __useOnListItemChangedSubscription__
 *
 * To run a query within a React component, call `useOnListItemChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnListItemChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnListItemChangedSubscription({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useOnListItemChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnListItemChangedSubscription, OnListItemChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OnListItemChangedSubscription, OnListItemChangedSubscriptionVariables>(OnListItemChangedDocument, baseOptions);
      }
export type OnListItemChangedSubscriptionHookResult = ReturnType<typeof useOnListItemChangedSubscription>;
export type OnListItemChangedSubscriptionResult = Apollo.SubscriptionResult<OnListItemChangedSubscription>;
export const RemoveCompletedListItemsDocument = gql`
    mutation removeCompletedListItems($listId: ID!) {
  removeCompletedListItems(listId: $listId)
}
    `;
export type RemoveCompletedListItemsMutationFn = Apollo.MutationFunction<RemoveCompletedListItemsMutation, RemoveCompletedListItemsMutationVariables>;

/**
 * __useRemoveCompletedListItemsMutation__
 *
 * To run a mutation, you first call `useRemoveCompletedListItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompletedListItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompletedListItemsMutation, { data, loading, error }] = useRemoveCompletedListItemsMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useRemoveCompletedListItemsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompletedListItemsMutation, RemoveCompletedListItemsMutationVariables>) {
        return Apollo.useMutation<RemoveCompletedListItemsMutation, RemoveCompletedListItemsMutationVariables>(RemoveCompletedListItemsDocument, baseOptions);
      }
export type RemoveCompletedListItemsMutationHookResult = ReturnType<typeof useRemoveCompletedListItemsMutation>;
export type RemoveCompletedListItemsMutationResult = Apollo.MutationResult<RemoveCompletedListItemsMutation>;
export type RemoveCompletedListItemsMutationOptions = Apollo.BaseMutationOptions<RemoveCompletedListItemsMutation, RemoveCompletedListItemsMutationVariables>;
export const CreateListDocument = gql`
    mutation createList($input: CreateListInput!) {
  createList(input: $input) {
    id
    name
  }
}
    `;
export type CreateListMutationFn = Apollo.MutationFunction<CreateListMutation, CreateListMutationVariables>;

/**
 * __useCreateListMutation__
 *
 * To run a mutation, you first call `useCreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListMutation, { data, loading, error }] = useCreateListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateListMutation(baseOptions?: Apollo.MutationHookOptions<CreateListMutation, CreateListMutationVariables>) {
        return Apollo.useMutation<CreateListMutation, CreateListMutationVariables>(CreateListDocument, baseOptions);
      }
export type CreateListMutationHookResult = ReturnType<typeof useCreateListMutation>;
export type CreateListMutationResult = Apollo.MutationResult<CreateListMutation>;
export type CreateListMutationOptions = Apollo.BaseMutationOptions<CreateListMutation, CreateListMutationVariables>;
export const ListsDocument = gql`
    query Lists {
  lists {
    id
    name
    shared
    owner {
      username
    }
  }
}
    `;

/**
 * __useListsQuery__
 *
 * To run a query within a React component, call `useListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListsQuery(baseOptions?: Apollo.QueryHookOptions<ListsQuery, ListsQueryVariables>) {
        return Apollo.useQuery<ListsQuery, ListsQueryVariables>(ListsDocument, baseOptions);
      }
export function useListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListsQuery, ListsQueryVariables>) {
          return Apollo.useLazyQuery<ListsQuery, ListsQueryVariables>(ListsDocument, baseOptions);
        }
export type ListsQueryHookResult = ReturnType<typeof useListsQuery>;
export type ListsLazyQueryHookResult = ReturnType<typeof useListsLazyQuery>;
export type ListsQueryResult = Apollo.QueryResult<ListsQuery, ListsQueryVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    jwt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ShareListsDocument = gql`
    mutation shareLists($input: ShareListsInput!) {
  shareLists(input: $input) {
    id
    username
  }
}
    `;
export type ShareListsMutationFn = Apollo.MutationFunction<ShareListsMutation, ShareListsMutationVariables>;

/**
 * __useShareListsMutation__
 *
 * To run a mutation, you first call `useShareListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareListsMutation, { data, loading, error }] = useShareListsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareListsMutation(baseOptions?: Apollo.MutationHookOptions<ShareListsMutation, ShareListsMutationVariables>) {
        return Apollo.useMutation<ShareListsMutation, ShareListsMutationVariables>(ShareListsDocument, baseOptions);
      }
export type ShareListsMutationHookResult = ReturnType<typeof useShareListsMutation>;
export type ShareListsMutationResult = Apollo.MutationResult<ShareListsMutation>;
export type ShareListsMutationOptions = Apollo.BaseMutationOptions<ShareListsMutation, ShareListsMutationVariables>;
export const ShareListsUsersDocument = gql`
    query shareListsUsers {
  shareListsUsers {
    id
    username
  }
}
    `;

/**
 * __useShareListsUsersQuery__
 *
 * To run a query within a React component, call `useShareListsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareListsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareListsUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useShareListsUsersQuery(baseOptions?: Apollo.QueryHookOptions<ShareListsUsersQuery, ShareListsUsersQueryVariables>) {
        return Apollo.useQuery<ShareListsUsersQuery, ShareListsUsersQueryVariables>(ShareListsUsersDocument, baseOptions);
      }
export function useShareListsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShareListsUsersQuery, ShareListsUsersQueryVariables>) {
          return Apollo.useLazyQuery<ShareListsUsersQuery, ShareListsUsersQueryVariables>(ShareListsUsersDocument, baseOptions);
        }
export type ShareListsUsersQueryHookResult = ReturnType<typeof useShareListsUsersQuery>;
export type ShareListsUsersLazyQueryHookResult = ReturnType<typeof useShareListsUsersLazyQuery>;
export type ShareListsUsersQueryResult = Apollo.QueryResult<ShareListsUsersQuery, ShareListsUsersQueryVariables>;
export const UnshareListsDocument = gql`
    mutation unshareLists($id: ID!) {
  unshareLists(id: $id)
}
    `;
export type UnshareListsMutationFn = Apollo.MutationFunction<UnshareListsMutation, UnshareListsMutationVariables>;

/**
 * __useUnshareListsMutation__
 *
 * To run a mutation, you first call `useUnshareListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnshareListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unshareListsMutation, { data, loading, error }] = useUnshareListsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnshareListsMutation(baseOptions?: Apollo.MutationHookOptions<UnshareListsMutation, UnshareListsMutationVariables>) {
        return Apollo.useMutation<UnshareListsMutation, UnshareListsMutationVariables>(UnshareListsDocument, baseOptions);
      }
export type UnshareListsMutationHookResult = ReturnType<typeof useUnshareListsMutation>;
export type UnshareListsMutationResult = Apollo.MutationResult<UnshareListsMutation>;
export type UnshareListsMutationOptions = Apollo.BaseMutationOptions<UnshareListsMutation, UnshareListsMutationVariables>;