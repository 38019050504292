import React from "react";
import Header from "client/components/Header";
import PageContent from "client/components/PageContent";

const Profile = () => {
  return (
    <div>
      <Header />
      <PageContent>Profile</PageContent>
    </div>
  );
};

export default Profile;
